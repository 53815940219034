import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DnDColumn, StyledDiv } from './styledComponents';
import { CloseOutlined } from '@ant-design/icons';
import { CloseButton } from '../styledComponents';
export const COLUMN_NAMES = {
  KEY_TO_PHRASE: 'Key to phrase',
  ACTIVE_KEY_TO_PHRASE: 'Active key to phrase'
};
export const MovableItem = ({
  id,
  name,
  index,
  currentColumnName,
  saveCannedTextToFacility,
  moveCardHandler,
  setItems,
  handleItemClick,
  cardClosable = false,
  closeCard,
  data
}) => {
  const changeItemColumn = (currentItem, columnName) => {
    console.log('hiii', currentItem, currentItem.data);
    currentItem.data.active = true;
    setItems(prevState => {
      const itemExists = prevState.some(
        existingItem => existingItem.id === currentItem.data.id
      );
      if (!itemExists) {
        return [...prevState, currentItem.data];
      }
      return prevState;
    });
  };

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'Our first type',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCardHandler(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { index, id, name, currentColumnName, data, type: 'Our first type' },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (dropResult) {
        const { name } = dropResult;
        const { KEY_TO_PHRASE, ACTIVE_KEY_TO_PHRASE } = COLUMN_NAMES;
        switch (name) {
          case ACTIVE_KEY_TO_PHRASE:
            changeItemColumn(item, ACTIVE_KEY_TO_PHRASE);
            break;
          case KEY_TO_PHRASE:
            changeItemColumn(item, KEY_TO_PHRASE);
            break;
          default:
            break;
        }
      }

      saveCannedTextToFacility(item);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.4 : 1;

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className='movable-item'
      style={{ opacity }}
      onClick={handleItemClick}
    >
      {name}
      {cardClosable && (
        <CloseButton
          icon={<CloseOutlined />}
          onClick={e => closeCard(e, id)}
        ></CloseButton>
      )}
    </div>
  );
};

export const Column = ({ children, className, title }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'Our first type',

    drop: () => ({ name: title }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
    // Override monitor.canDrop() function

    // canDrop: item => {
    //   const { KEY_TO_PHRASE, ACTIVE_KEY_TO_PHRASE } = COLUMN_NAMES;
    //   const { currentColumnName } = item;

    //   return (
    //     currentColumnName === title ||
    //     (currentColumnName === ACTIVE_KEY_TO_PHRASE &&
    //       title === KEY_TO_PHRASE) ||
    //     (currentColumnName === KEY_TO_PHRASE && title === ACTIVE_KEY_TO_PHRASE)
    //   );
    // }
  });

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return 'rgb(246, 246, 246)';
      } else if (!canDrop) {
        // return 'rgb(246, 246, 246)';
      }
    } else {
      return '';
    }
  };

  return (
    <DnDColumn ref={drop} style={{ backgroundColor: getBackgroundColor() }}>
      {children}
    </DnDColumn>
  );
};
